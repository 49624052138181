import * as React from 'react';
import clsx from 'clsx';
import Octicon, { Trashcan, Checklist, Unmute, Reply, Play, Mute } from '@primer/octicons-react';

import { IconButton } from './Button';
import { ReviewItemT, ReviewStateEnum, ReviewT } from '../../commonTypes';

export const WordReviewBlock = (props: {
  word: string;
  items: ReviewItemT[];
  submitReviews: (items: ReviewItemT[]) => void;
  addItemReview: (itemId: string, itemReview?: ReviewT) => void;
}) => {
  const { word, items } = props;

  const reviewApi = {
    modifyItem: (item: ReviewItemT, word: string) => {
      props.addItemReview(item.id, {
        status: ReviewStateEnum.MODIFIED,
        word,
        originalWord: item.originalWord ?? item.word,
      });
    },
    rejectItem: (item: ReviewItemT) => {
      props.addItemReview(item.id, { status: ReviewStateEnum.REJECTED });
    },
    resetItem: (item: ReviewItemT) => {
      props.addItemReview(item.id, undefined);
    },
  };

  const submitReviews = () => {
    props.submitReviews(items);
  };

  /**
   * Edit word
   */
  const wordInputEl = React.useRef<HTMLInputElement>();
  const [editItemId, set_editItemId] = React.useState('');

  /**
   * Audio and auto play
   */
  const autoPlayRef = React.useRef(false);
  const [playItemId, set_playItemId] = React.useState('');

  const playItem = (item: ReviewItemT) => {
    set_playItemId(item.id);
  };
  const playAll = () => {
    const item = items[0];
    if (item) {
      autoPlayRef.current = true;
      playItem(item);
    }
  };
  const stopAutoPlay = () => {
    autoPlayRef.current = false;
    set_playItemId('');
  };

  const playItemIndex = items.findIndex((_item) => _item.id === playItemId);
  const audioUrl = items[playItemIndex]?.mediaUrl;
  const itemIdToPlayNext = items[playItemIndex + 1]?.id;

  React.useEffect(() => {
    if (!audioUrl) {
      return;
    }

    let isCancelled = false;
    const audio = new Audio(audioUrl);
    const onPlayEnd = () => {
      if (isCancelled) {
        return;
      }
      if (autoPlayRef.current && itemIdToPlayNext) {
        set_playItemId(itemIdToPlayNext);
      } else {
        set_playItemId('');
        autoPlayRef.current = false;
      }
    };

    audio.addEventListener('ended', onPlayEnd);
    audio.play();

    return () => {
      isCancelled = true;
      audio.removeEventListener('ended', onPlayEnd);
      audio.pause();
    };
  }, [audioUrl, itemIdToPlayNext]);

  return (
    <div className="mt-12">
      <div className="flex justify-between mb-2 px-5">
        <h2 className="font-semibold text-xl">{word}</h2>
        {autoPlayRef.current && audioUrl ? (
          <IconButton
            size="sm"
            onClick={stopAutoPlay}
            icon={<Octicon icon={Mute} size={20} />}
            children="Stop"
          />
        ) : (
          <IconButton
            size="sm"
            onClick={playAll}
            icon={<Octicon icon={Unmute} size={20} />}
            children="Play all"
          />
        )}
      </div>
      {items.map((item, i) => (
        <div
          key={item.id}
          className={clsx('flex items-center py-2 px-2 border-b', {
            'bg-red-300': item.status === ReviewStateEnum.REJECTED,
            'bg-purple-500': playItemId === item.id,
          })}
        >
          <div className="text-3xl pl-2 pr-2 mr-3 text-right" style={{ flexBasis: '50px' }}>
            {i + 1}
          </div>
          <div className="h-10 w-10 flex items-center justify-center mr-3">
            {item.status === ReviewStateEnum.REJECTED ||
            item.status === ReviewStateEnum.MODIFIED ? (
              <button
                type="button"
                className="cursor-pointer"
                onClick={() => reviewApi.resetItem(item)}
              >
                <Octicon icon={Reply} className="text-green-500" size={26} />
              </button>
            ) : (
              <button
                type="button"
                className="cursor-pointer"
                onClick={() => reviewApi.rejectItem(item)}
              >
                <Octicon icon={Trashcan} className="text-red-700" size={26} />
              </button>
            )}
          </div>

          {editItemId === item.id ? (
            <div className="flex-grow">
              <form
                className="flex flex-grow items-center justify-between"
                onSubmit={(event) => {
                  event.preventDefault();
                  const newWord = wordInputEl.current?.value ?? '';
                  set_editItemId('');
                  if (newWord.length >= 2) {
                    reviewApi.modifyItem(item, newWord);
                  }
                }}
              >
                <input
                  key={item.id}
                  ref={wordInputEl}
                  name="word"
                  defaultValue={item.status === ReviewStateEnum.MODIFIED ? item.word : ''}
                  className="border-2 border-orange-500 uppercase"
                />
                <button
                  type="submit"
                  className="py-1 px-3 rounded text-lg bg-orange-500 text-white uppercase cursor-pointer"
                  children="Save"
                />
              </form>
            </div>
          ) : (
            <div className="flex flex-grow items-center justify-between">
              <div className="flex-grow">
                <div className="flex-grow">
                  <h3 className="inline-block text-2xl leading-6 font-bold uppercase">
                    {item.word}
                  </h3>
                  {item.originalWord && (
                    <p className="inline-block ml-2">
                      {' '}
                      from <span className="uppercase">{item.originalWord}</span>
                    </p>
                  )}
                </div>
                <div>
                  {[
                    { label: 'YES', value: 'Yes' },
                    { label: 'NO', value: 'No' },
                    { label: 'NOISE', value: '_NOISE' },
                  ].map(({ label, value }, i) => (
                    <button
                      key={value}
                      type="button"
                      className={`text-lg text-orange-500 uppercase cursor-pointer pr-2 pt-1 mr-2 hover:opacity-50 ${
                        i > 0 ? 'pl-2' : ''
                      }`}
                      children={label}
                      onClick={() => reviewApi.modifyItem(item, value)}
                    />
                  ))}
                </div>
              </div>
              <button
                type="button"
                onClick={() => set_editItemId(item.id)}
                className="text-lg text-orange-500 uppercase cursor-pointer"
                children="Edit"
              />
            </div>
          )}

          <div className="ml-5 cursor-pointer" onClick={() => playItem(item)}>
            <Octicon icon={Play} className="text-black-500" size={42} />
          </div>
        </div>
      ))}
      <div className="flex justify-end pb-6 mt-2 px-5">
        <IconButton
          size="sm"
          color="success"
          onClick={submitReviews}
          icon={<Octicon icon={Checklist} size={20} />}
          children="Submit"
        />
      </div>
    </div>
  );
};

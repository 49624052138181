import last from 'lodash/last';
import * as React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useToasts } from 'react-toast-notifications';
import { useLocalStorageState } from 'use-local-storage-state';

import { ReviewItemT, ReviewMapT, ReviewStateEnum, ReviewT } from '../../commonTypes';
import { useApi } from './api';
import { WordReviewBlock } from './WordReviewBlock';

export function ReviewPage() {
  const api = useApi();
  const { addToast } = useToasts();

  // Configure query
  const [count, set_count] = useLocalStorageState('COUNT', 10);
  const [startAt, set_startAt] = React.useState<string | undefined>('A');

  const reviewItemsQuery = useQuery(
    ['reviewItems', { count, startAt }],
    () => api.getReviewItems({ count, startAt }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      placeholderData: [],
    }
  );

  const queryClient = useQueryClient();
  const submitReviewMutation = useMutation(api.submitReview, {
    onSuccess: (_, data) => {
      addToast('Saved reviews');

      // in case word was changed
      const lastItem = last(data);
      const lastWord = lastItem?.originalWord ?? lastItem?.word;
      if (lastWord !== startAt) {
        set_startAt(lastWord);
      }
      // invalidate and refetch
      queryClient.invalidateQueries('reviewItems');
    },
    onError: (error) => {
      addToast((error as Error)?.message ?? 'Error', { appearance: 'error' });
    },
  });

  const [reviews, set_reviews] = React.useState<ReviewMapT>({});
  const addItemReview = React.useCallback(
    (itemId: string, review: ReviewT) => {
      set_reviews((existing) => ({ ...existing, [itemId]: review }));
    },
    [set_reviews]
  );

  const reviewItems = reviewItemsQuery.data;
  const groupedWords = React.useMemo(() => {
    if (!reviewItems) {
      return [];
    }
    const groupedItems = new Map<string, ReviewItemT[]>();
    for (const item of reviewItems) {
      const wordArr = groupedItems.get(item.word) || [];
      groupedItems.set(item.word, wordArr.concat(item));
    }
    return Array.from(groupedItems.entries());
  }, [reviewItems]);

  return (
    <div>
      <div className="px-5">
        <label htmlFor="select-startAt" children="Start fetching from:" />
        <select
          id="select-startAt"
          name="startAt"
          className="w-full px-2 py-1 bg-gray-300"
          value={startAt}
          onChange={(e) => set_startAt(e.target.value)}
        >
          {startAt.length > 1 ? <option value={startAt}>{startAt}</option> : null}
          {'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').map((char) => (
            <option key={char} value={char}>
              {char}
            </option>
          ))}
        </select>
      </div>
      <div className="px-5">
        <label htmlFor="select-count" children="Number of audios to fetch:" />
        <select
          id="select-count"
          name="count"
          className="w-full px-2 py-1 bg-gray-300"
          value={count}
          onChange={(e) => set_count(parseInt(e.target.value, 10))}
        >
          {[10, 25, 50, 75, 100].map((count_option) => (
            <option key={count_option} value={count_option}>
              {count_option}
            </option>
          ))}
        </select>
      </div>

      {reviewItemsQuery.isFetching ? (
        <h3 className="text-center mt-5">Loading..</h3>
      ) : (
        groupedWords.map(([word, items]) => {
          const reviewItems: ReviewItemT[] = items.map((item) => ({
            ...item,
            ...reviews[item.id],
          }));

          return (
            <WordReviewBlock
              key={word}
              word={word}
              items={reviewItems}
              addItemReview={addItemReview}
              submitReviews={() => {
                const payload = reviewItems.map((item) => ({
                  ...item,
                  status:
                    item.status === ReviewStateEnum.AWAITING_REVIEW
                      ? ReviewStateEnum.ACCEPTED
                      : item.status,
                }));

                console.log('Submitting', payload);
                submitReviewMutation.mutate(payload);
              }}
            />
          );
        })
      )}
    </div>
  );

  return null;
}

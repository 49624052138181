import { Provider as JotaiProvider } from 'jotai';
import * as React from 'react';
import ReactDom from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastProvider } from 'react-toast-notifications';

import { useAuthCode } from './api';
import { Header } from './Header';
import { ReviewPage } from './ReviewPage';

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

const App = () => {
  const [authCode, set_authCode] = useAuthCode();

  return (
    <div className="max-w-3xl m-auto">
      <Header />

      <div className="pt-3 pb-3 pr-2 pl-2 bg-gray-300 flex justify-between mt-5 mb-5">
        <label className="">Auth code</label>
        <input
          className="border-1"
          value={authCode}
          onChange={(e) => set_authCode(e.target.value)}
        />
      </div>

      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
        }}
      >
        <React.Suspense fallback={<h1>LOADING</h1>}>
          <ReviewPage />
        </React.Suspense>
      </ErrorBoundary>
    </div>
  );
};

const queryClient = new QueryClient();

const Root = () => {
  return (
    <JotaiProvider>
      <QueryClientProvider client={queryClient}>
        <ToastProvider autoDismiss={true} autoDismissTimeout={2500}>
          <App />
        </ToastProvider>
      </QueryClientProvider>
    </JotaiProvider>
  );
};

ReactDom.render(<Root />, document.getElementById('app-root'));

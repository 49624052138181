// types
export enum ReviewStateEnum {
  AWAITING_REVIEW = 'awaiting-review',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  MODIFIED = 'accepted-modified',
}

export interface ReviewDocItemT {
  bucket: string;
  filename: string;
  sessionId: string;
  word: string;
  originalWord?: string;
  status: ReviewStateEnum;
}

export interface ReviewItemT extends ReviewDocItemT {
  id: string;
  mediaUrl: string;
}

// Types for reviews
export type ReviewT =
  | { status: ReviewStateEnum.ACCEPTED }
  | { status: ReviewStateEnum.REJECTED }
  | { status: ReviewStateEnum.MODIFIED; word: string; originalWord: string };

export type ReviewMapT = Record<string, ReviewT>;

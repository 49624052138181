import * as React from 'react';
import clsx from 'clsx';

type HtmlButtonPropsT = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;
interface ButtonPropsT extends HtmlButtonPropsT {
  variant?: 'contained' | 'text';
  color?: 'secondary' | 'danger' | 'success';
  size?: 'sm' | 'md' | 'lg' | 'xl';
}
export function Button({
  variant = 'contained',
  color = 'secondary',
  size = 'lg',
  ...buttonProps
}: ButtonPropsT) {
  let bgColor = '';
  if (variant === 'contained') {
    const padding = clsx({
      'px-2 py-2': size === 'sm',
      'px-4 py-2': size === 'md',
      'px-12 py-3': size === 'lg',
      'px-20 lg:px-32 py-3': size === 'xl',
    });
    bgColor = clsx('rounded-full', padding, 'text-blue-900', {
      'bg-red-500': color === 'danger',
      'bg-orange-500': color === 'secondary',
      'bg-green-500': color === 'success',
    });
  } else {
    bgColor = clsx('bg-transparent', {
      'text-red-500': color === 'danger',
      'text-blue-900': color === 'secondary',
      'text-green-500': color === 'success',
    });
  }

  return (
    <button
      {...buttonProps}
      className={clsx(bgColor, {
        'text-md': size === 'sm',
        'text-lg': size === 'md',
        'text-xl': size === 'lg',
        'text-2xl': size === 'xl',
        'cursor-pointer': !buttonProps.disabled,
        'font-semibold uppercase': true,
      })}
    />
  );
}

export function IconButton(
  props: ButtonPropsT & { icon: JSX.Element; children: React.ReactNode }
) {
  return (
    <Button
      {...props}
      children={
        <span className="flex items-center pl-2 pr-2">
          <span children={props.icon} className="pr-2" />
          {props.children}
        </span>
      }
    />
  );
}

import { atom, useAtom } from 'jotai';
import QS from 'query-string';
import * as React from 'react';

import { ReviewItemT } from '../../commonTypes';

const DOMAIN = 'https://europe-west2-hoopteller.cloudfunctions.net';
// const DOMAIN = 'http://localhost:5001/hoopteller/europe-west2';

// ============================================================================
// Auth Code

const __authCodeAtom = atom(localStorage.getItem('AUTH_CODE'));

const authCodeAtom = atom(
  (get) => get(__authCodeAtom),
  (get, set, value: string) => {
    localStorage.setItem('AUTH_CODE', value);
    set(__authCodeAtom, value);
  }
);

export const useAuthCode = () => useAtom(authCodeAtom);

// ============================================================================
// Api

export const useApi = () => {
  const [authCode] = useAuthCode();

  const api = React.useMemo(() => {
    return {
      getReviewItems: (params: { startAt?: string; count?: number }): Promise<ReviewItemT[]> => {
        const code = encodeURIComponent(authCode);
        const queryParams = QS.stringify({ ...params, code });
        const url = `${DOMAIN}/api/getReviewItems?${queryParams}`;

        console.log(`GET /api/getReviewItems`);

        return fetch(url, { method: 'GET', mode: 'cors' })
          .then((val) => val.json() as Promise<ReviewItemT[]>)
          .then((items) => {
            console.log(`Got ${items.length} items to review`);
            return items;
          });
      },
      submitReview: (data: ReviewItemT[]) => {
        const code = encodeURIComponent(authCode);
        console.log(`POST /api/submitReview`);
        const url = `${DOMAIN}/api/submitReview?${QS.stringify({ code })}`;
        return fetch(url, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }).then(async (res) => {
          if (res && res.ok) {
            return res.json();
          }

          let errorMessage = 'Failed to submit review items';
          try {
            const { message } = await res.json();
            errorMessage = message;
          } catch (error) {
            // no-op
          }
          return Promise.reject(new Error(errorMessage));
        });
      },
    };
  }, [authCode]);

  return api;
};
